import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Field, ErrorMessage, useField } from 'formik';
import InputMask from 'react-input-mask';

import InputWrapper from '../input-wrapper';
import Typography from '../../atoms/typography';

// eslint-disable-next-line
const MaskedInput = ({ field, form, ...props }) => <InputMask {...field} {...props} />;

const errorStyles = (props) => css`
  border-color: ${props.newCheckout ? props.theme.color.redError : props.theme.color.brandError};
  color: ${props.newCheckout ? props.theme.color.redError : props.theme.color.brandError};
`;

const InputFieldStyles = (props) => css`
  appearance: none;
  background: ${(props.newCheckout ? props.theme.color.backgroundLightBlue : props.theme.color.brandNeutral)};
  border: ${(props.newCheckout ? `1px solid ${props.theme.color.borderBlue}` : 'none')};
  box-sizing: border-box;
  color: ${props.theme.color.brand};
  font-family: ${props.theme.font.sans};
  font-size: ${(props.newCheckout ? '18px' : props.theme.modularScale.small)};
  height: 4rem;
  letter-spacing: 0.04em;
  outline-color: ${props.theme.color.brandLight};
  outline: 0;
  padding: ${(props.type === 'date' ? '1.25rem 1.5rem' : '0 1.5rem')};
  width: 100%;

  ::placeholder {
    color: ${props.theme.color.brandLight};
  }

  :-internal-autofill-selected {
    /* TODO: go away Google! */
    color: ${props.theme.color.brand} !important;
    -webkit-text-fill-color: ${props.theme.color.brand} !important;
  }

  :-webkit-autofill{
    -webkit-text-fill-color: ${props.theme.color.brand} !important;
  }

  ::selection {
    -webkit-text-fill-color: white;
  }

  ::invalid {
    box-shadow: none;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  & ~ & {
    margin-top: 1rem;
  }

  @media (max-width: ${props.theme.breakpoint.medium}) {
    font-size: 16px;
  }
`;

const ErrorMsg = styled(ErrorMessage, { shouldForwardProp: (prop) => prop !== 'newCheckout' })`
  color: ${(props) => (props.newCheckout ? props.theme.color.redError : props.theme.color.brandError)};
  background-color: var(--background-color);
  z-index: ${(props) => props.theme.layers.upstage - 1};
  display: block;
  font-family: ${(props) => props.theme.font.sans};
  font-size: ${(props) => (props.newCheckout ? '1.6rem' : '1.2rem')};
  left: 0;
  margin-top: 0.25rem;
  position: absolute;
  top: 100%;
  width: 100%;
`;

const WireframeWrapper = styled(InputWrapper)`
  display: flex;
  flex-direction: column-reverse;
  margin: .4rem 0;
  position: relative;

  input {
    ${InputFieldStyles}
    background: transparent;
    border-bottom: 2px solid ${(props) => props.theme.color.brand};
    border-radius: 0;
    padding: 0;
    &.masked-mobile {
      padding-left: 8.5rem;
    }
    &.masked {
      padding-left: 8.5rem;
    }
  }
  ${ErrorMsg} + input {
    ${errorStyles}
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: .2rem 0;
  position: relative;

  input {
    ${InputFieldStyles}
    &.masked-mobile {
      padding-left: 8.5rem;
    }
    &.masked {
      padding-left: 8.5rem;
    }
  }

  ${ErrorMsg} + input {
    border: 0.2rem solid transparent;
    ${errorStyles}

    ::placeholder {
      color: ${(props) => props.theme.color.brandError};
      opacity: 0.6;
    }
  }
`;

const Label = styled(Typography, { shouldForwardProp: (prop) => prop !== 'newCheckout' })`
  color: ${(props) => props.theme.color.brand};
  margin-bottom: 0.5rem;
  font-size: ${(props) => (props.newCheckout ? '18px' : props.theme.modularScale.small)};
`;
// TODO: refactor out inpout wrapper
const FormikField = forwardRef((props, ref) => {
  const meta = useField(props.name)[1];

  return (
    <>
      { props.isWireframe
        ? (
          <WireframeWrapper
            className={props.className}
            for={props.id}
            label={props.label}
            newCheckout={props.newCheckout}
          >
            <ErrorMsg
              id={`${props.id}-error`}
              role="alert"
              name={props.name}
              component="span"
              newCheckout={props.newCheckout}
            />
            {props.mask ? (
              <Field
                mask={props.mask}
                permanents={props.permanents}
                aria-describedby={meta.touched && meta.error ? `${props.id}-error` : null}
                ref={ref}
                id={props.id}
                name={props.name}
                placeholder={props.placeholder}
                type={props.type}
                validate={props.validate}
                autoComplete={props.autoComplete}
                component={MaskedInput}
                className={props.mobileView ? 'masked-mobile' : 'masked'}
              />
            ) : (
              <>
                <Field
                  aria-describedby={meta.touched && meta.error ? `${props.id}-error` : null}
                  innerRef={ref}
                  id={props.id}
                  name={props.name}
                  placeholder={props.placeholder}
                  type={props.type}
                  validate={props.validate}
                  autoComplete={props.autoComplete}
                />
              </>
            )}
          </WireframeWrapper>
        ) : (
          <Wrapper className={props.className} newCheckout={props.newCheckout}>
            <ErrorMsg id={`${props.id}-error`} role="alert" name={props.name} data-test-id ={`${props.dataTestId}_error`} component="span" newCheckout={props.newCheckout} />
            {props.mask ? (
              <Field
                mask={props.mask}
                permanents={props.permanents}
                aria-describedby={meta.touched && meta.error ? `${props.id}-error` : null}
                ref={ref}
                id={props.id}
                name={props.name}
                placeholder={props.placeholder}
                type={props.type}
                validate={props.validate}
                autoComplete={props.autoComplete}
                component={MaskedInput}
                className={props.mobileView ? 'masked-mobile' : 'masked'}
              />
            ) : (
              <Field
                aria-describedby={meta.touched && meta.error ? `${props.id}-error` : null}
                innerRef={ref}
                id={props.id}
                name={props.name}
                placeholder={props.placeholder}
                type={props.type}
                validate={props.validate}
                autoComplete={props.autoComplete}
              />
            )}
            {
              props.label && <Label element="label" like="dec-1" htmlFor={props.id} newCheckout={props.newCheckout}>{props.label}</Label>
            }
          </Wrapper>
        ) }
    </>
  );
});

FormikField.defaultProps = {
  className: '',
  isWireframe: false,
  label: '',
  validate: () => {},
  mask: undefined,
  permanents: undefined,
  mobileView: false,
  newCheckout: false,
  dataTestId:''
};

FormikField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isWireframe: PropTypes.bool,
  validate: PropTypes.func,
  autoComplete: PropTypes.string.isRequired,
  mask: PropTypes.array,
  permanents: PropTypes.array,
  mobileView: PropTypes.bool,
  newCheckout: PropTypes.bool,
  dataTestId:PropTypes.string,
};

FormikField.whyDidYouRender = true;

export default FormikField;
