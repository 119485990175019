import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Typography from '../../atoms/typography';

const Label = styled(Typography)`
  line-height: 1;
  z-index: ${({ theme }) => theme.layers.backstage};
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column-reverse;
  position: relative;

  > span {
    order: -1;
  }

  > input {
    z-index: ${({ theme }) => theme.layers.backstage + 1};
  }

  ${Label} {
    color: ${(props) => props.theme.color[props.labelColor]};
  }

  input::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  input::-moz-placeholder {
    opacity: 0;
    transition: inherit;
  }

  ${Label}, input {
    touch-action: manipulation;
    transition: all 200ms;
  }

  input:placeholder-shown ~ ${Label} {
    transform: translate(0, 2.8rem);
  }

  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  input:focus::-moz-placeholder {
    opacity: 1;
  }

  input:not(:placeholder-shown) ~ ${Label},
  input:focus ~ ${Label} {
    color: ${(props) => props.theme.color[props.labelFocusColor]};
    transform: translate(0, 0.75rem);
  }
`;

const InputWrapper = (props) => (
  <Wrapper
    className={props.className}
    labelFocusColor={props.labelFocusColor}
    labelColor={props.labelColor}
  >
    {props.children}
    <Label
      htmlFor={props.for}
      element="label"
      like={props.labelLike}
    >
      {props.label}
    </Label>
  </Wrapper>
);

InputWrapper.defaultProps = {
  className: '',
  labelColor: 'brandLight',
  labelFocusColor: 'brand',
  labelLike: 'dec-1'
};

InputWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  for: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  labelFocusColor: PropTypes.string,
  labelLike: PropTypes.string
};

export default InputWrapper;
